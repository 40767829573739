import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import "../styles/TestEditor.scss"
import { deleteImage, putCorrection } from "../store/slices/examRatingSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faPlus, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';

export function TestEditor(props) {
    const dispatch = useDispatch();
    const [correction, setCorrection] = useState(props.correction);
    const [showLoadingBar, setLoadingBar] = useState(false);
    const [isInDropArea, setIsInDropArea] = useState(false);

    useEffect(() => {
        setCorrection(props.correction)
    }, [props.correction])


    function addCorrection() {
        props.setCorrection(correction.id, correction.plainText, correction.image)
        props.addCorrections()
    }

    function handleTextChange(e) {
        setCorrection({ ...correction, plainText: e.target.value })
        props.setCorrection(correction.id, e.target.value, correction.image)
    }

    function needsMoreInput() {
        // 'nicht bearbeitet' and 'ohne wesentliche beanstandung' dont need further explanation
        return props.correction.rating !== 1 && props.correction.rating !== 8;
    }

    function getTextArea() {
        if (needsMoreInput()) {
            return <textarea value={correction.plainText} onChange={handleTextChange} />;
        }
        else {
            return <textarea disabled />;
        }
    }

    const handleFileUpload = (e) => {
        setLoadingBar(true)
        let file = e.target?.files[0];
        setImage(file)
    }

    const handleImageDelete = () => {
        setImage("")
        dispatch(deleteImage({
            correction: correction
        }))
    }

    function setImage(file) {
        setCorrection({ ...correction, image: file })
        props.setCorrection(correction.id, correction.plainText, file)
        setLoadingBar(false)
    }

    const handleDragEnter = e => {
        e.preventDefault();
        e.stopPropagation();
        setIsInDropArea(true);
    };
    const handleDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
        setIsInDropArea(false);
    };
    const handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
        setIsInDropArea(true);
    };
    const handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        setIsInDropArea(false);
        const file = e.dataTransfer.files[0]

        if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
            setImage(file)
        }
    };

    return (
        <div className="editor-container">
            <div className="test-order">{'Aufgabe ' + correction.orderNumber}</div>
            <div className="test-text">
                <div className="initial-text">
                    {getTextArea()}
                </div>
                <div className="parse-btn-container">
                    <a className="rate btn outline-btn" onClick={addCorrection}>Aufgabe speichern</a>
                </div>
                {
                    correction.latexErrorMessage ?
                        <div className='latex-text error'>
                            <div className="latex-error"> Latex Error:</div>
                            <div className="latex-error-message">{correction.latexErrorMessage}</div>
                        </div> :
                        <div className={correction.generated_png ? 'latex-text' : 'latex-text empty'}>
                            {
                                correction.generated_png ?
                                    <img className='generated-png' src={"data:image/png;base64," + correction.generated_png} /> :
                                    <div className="png-place-holder">LaTex Output</div>

                            }
                        </div>
                }

            </div>
            Photo Upload
            <div className='image-row'>
                <div className='button-column'>
                    <div className='image-upload-container'>
                        <label className={'btn outline-btn file-upload-btn'}>
                            <input id="file-upload" type="file" accept="image/png, image/jpeg" onChange={handleFileUpload} />
                            <FontAwesomeIcon className="fa-icon" icon={showLoadingBar ? faCircleNotch : faPlus} spin={showLoadingBar ? true : false} />
                            Foto auswählen
                        </label>
                    </div>
                    <div className='button-column-spacer' />
                    {correction.image && <div className='image-upload-container'>
                        <label className={'btn outline-btn file-upload-btn'} onClick={handleImageDelete}>
                            <FontAwesomeIcon className="fa-icon" icon={faTrash} />
                            Foto löschen
                        </label>
                    </div>}
                </div>
                <div className='image-container'>
                    {correction.image ?
                        <img className='image-display' src={typeof correction.image === 'string' || correction.image instanceof String ? "data:image/png;base64," + correction.image : URL.createObjectURL(correction.image)} /> :
                        <div className='image-drag-container'>
                            <p>or drag photo here:</p>
                            <div className={isInDropArea ? 'image-drag-target inside-drag-area' : 'image-drag-target'}
                                onDrop={e => handleDrop(e)}
                                onDragOver={e => handleDragOver(e)}
                                onDragEnter={e => handleDragEnter(e)}
                                onDragLeave={e => handleDragLeave(e)} >
                                <FontAwesomeIcon className="fa-icon" icon={faUpload} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}