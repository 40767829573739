import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import "../styles/Header.scss"
import {SignOutButton} from "./SignOutButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCircleNotch, faPlus} from "@fortawesome/free-solid-svg-icons";
import {faArrowAltCircleDown, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {useLocation} from "react-router-dom";
import {batchAddExams, deleteAllData, getAllExamsPDF, getAnalyticsCSV, getExams} from "../store/slices/examsSlice";
import {selectCurrentCorrectorNum} from "../store/slices/appSlice";
import Papa from 'papaparse';
import Modal from 'react-modal';

export function Header(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const [displayButtons, setDisplayButtons] = useState(false);
    const [showLoadingBar, setLoadingBar] = useState(false);
    const [fileUploadMessage, setFileUploadMessage] = useState('');
    const [uploadDone, setUploadDone] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [downloadPdfPending, setDownloadPdfPending] = useState(false);
    const [downloadAnalyticsPending, setDownloadAnalyticsPending] = useState(false);
    const user = props.user || {};
    const userRoles = user.account?.idTokenClaims.roles || [];
    const isAdmin = userRoles && userRoles.length ? userRoles.includes("admin") : false;
    const currentCorrectorNum = useSelector(selectCurrentCorrectorNum);

    useEffect(() => {
        console.log("Header initialized");
        const path = location.pathname;
        let display = path === "/";
        setDisplayButtons(display);
    }, [location]);

    function handleFileUpload(e) {
        let file = e.target?.files[0];
        if (file) {
            let reader = new FileReader();
            reader.onloadstart = (event) => {
                setLoadingBar(true)
            };
            reader.onloadend = (event) => {
            };
            reader.onload = (e) => {
                let examsStringArray = Papa.parse(e.target.result, {skipEmptyLines: true}).data;
                if (examsStringArray.length && (
                    (examsStringArray[0].length !== 5 && examsStringArray[0].length !== 6) ||
                    (examsStringArray[0][0].length > 7))) {

                    setFileUploadMessage('Wrong fields in the file');
                    setLoadingBar(false)

                    setTimeout(() => {
                        setFileUploadMessage('');
                    }, 10000)
                    return false;
                }
                let mappedArray = mapCSVToObject(examsStringArray);
                dispatch(batchAddExams({exams: mappedArray}))
                    .then(() => {
                        setLoadingBar(false)
                        setUploadDone(true)
                        return dispatch(getExams())
                    })
                    .then(() => {
                        setTimeout(() => {
                            setUploadDone(false)
                        }, 30000)
                    })
            }
            reader.readAsText(file);
            e.target.value = '';
            console.log("File uploaded")
        }

    }

    function mapCSVToObject(strArr) {

        let exams = strArr.map((strArr) => {
            let examObj = {};
            examObj.student = {
                "id": 0,
            }
            examObj.student.number = strArr[0];
            examObj.student.name = strArr[2] + ' ' + strArr[1];
            examObj.firstCorrector = strArr[3];
            examObj.secondCorrector = strArr[4];
            examObj.sortIndex = parseInt(strArr[5]);
            examObj.corrections = [
                {
                    "id": 0,
                    "correctionCompleted": false,
                    "orderNumber": 1,
                    "plainText": "",
                    "rating": 0,
                },
                {
                    "id": 0,
                    "correctionCompleted": false,
                    "orderNumber": 2,
                    "plainText": "",
                    "rating": 0,
                },
                {
                    "id": 0,
                    "correctionCompleted": false,
                    "orderNumber": 3,
                    "plainText": "",
                    "rating": 0,
                },
                {
                    "id": 0,
                    "correctionCompleted": false,
                    "orderNumber": 4,
                    "plainText": "",
                    "rating": 0,
                }
            ];

            return examObj;
        })
        return exams;
    }

    function downloadPDF() {
        setDownloadPdfPending(true);
        dispatch(getAllExamsPDF())
            .finally(() => setDownloadPdfPending(false));
    }

    function downloadAnalytics() {
        setDownloadAnalyticsPending(true);
        dispatch(getAnalyticsCSV())
            .finally(() => setDownloadAnalyticsPending(false));
    }

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    function deleteData() {
        dispatch(deleteAllData())
            .then(() => {
                closeModal();
                dispatch(getExams({correctorNum: currentCorrectorNum}))
            })
    }

    return (
        <div className="header">
            <div className="logo-container">
                <div className="logo"/>
            </div>
            <div className="btn-container">
                {
                    displayButtons && isAdmin ?
                        <React.Fragment>
                            <a className="btn delete-btn" onClick={openModal}>
                                <FontAwesomeIcon className="fa-icon" icon={faTrashAlt}/>
                                Delete
                            </a>


                            <label className={'btn outline-btn file-upload-btn'}>
                                <input id="file-upload" type="file" accept=".csv" onChange={handleFileUpload}/>
                                {
                                    showLoadingBar ?
                                        <React.Fragment>
                                            <FontAwesomeIcon className="fa-icon" icon={faCircleNotch} spin/>Teiln.
                                            hinzufügen
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <FontAwesomeIcon className={"fa-icon " + (uploadDone ? "green" : "")}
                                                             icon={uploadDone ? faCheck : faPlus}/>Teiln. hinzufügen
                                        </React.Fragment>
                                }

                            </label>
                            <button className="btn grey-btn" disabled={downloadPdfPending} onClick={downloadPDF}>
                                {downloadPdfPending && (
                                    <FontAwesomeIcon className="spinner" icon={faCircleNotch} spin/>
                                )}
                                <FontAwesomeIcon className="fa-icon" icon={faArrowAltCircleDown}/>
                                Download PDFs
                            </button>
                            <button className="btn grey-btn" disabled={downloadAnalyticsPending} onClick={downloadAnalytics}>
                                {downloadAnalyticsPending && (
                                    <FontAwesomeIcon className="spinner" icon={faCircleNotch} spin/>
                                )}
                                <FontAwesomeIcon className="fa-icon" icon={faArrowAltCircleDown}/>
                                Download Auswertung
                            </button>
                            <SignOutButton/>
                        </React.Fragment>
                        :
                        <SignOutButton/>
                }

            </div>
            {
                fileUploadMessage &&

                <div className="error-container">
                    {fileUploadMessage}
                </div>
            }
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                // style={customStyles}
                className="confirmation-modal"
                contentLabel="Example Modal"
            >
                <div className="modal-title">Confirmation</div>
                <div className="modal-text">
                    Are you sure you want to delete all data ?
                </div>
                <div className="modal-buttons-container">
                    <a className="btn grey-btn" onClick={deleteData}>
                        Confirm
                    </a>
                    <a className="btn outline-btn" onClick={closeModal}>
                        Cancel
                    </a>
                </div>
            </Modal>
        </div>
    )
}
