import { LogLevel } from "@azure/msal-browser";
const redirectUri = process.env.REACT_APP_AUTH_REDIRECT_URL

export const msalConfig = {
    auth: {
        clientId: "4d6f9d78-74d3-4b14-8ad0-a1ec1d8eaeb4",
        authority: "https://login.microsoftonline.com/0368d34b-9df3-48a8-b503-c1c1c703dbfe", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: redirectUri,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
// export const graphConfig = {
//     graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
// };