import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ratingOptionsGet } from "../../api/api";


export const getRatingOptions = createAsyncThunk(
    'getRatingOptions',
    async (params, { getState }) => {
        const state = getState();
        if (!state.app.ratings) {
            const response = await ratingOptionsGet(params)
            return response
        } else {
            return state.app.ratings
        }
    }
)

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        user: {},
        ratings: null,
        correctorNum: ''
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setCurrentCorrectorNum: (state, action) => {
            state.correctorNum = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRatingOptions.pending, (state, action) => {
            })
            .addCase(getRatingOptions.fulfilled, (state, action) => {
                let ratings = JSON.parse(JSON.stringify(action.payload))
                ratings.fields.sort((a, b) => {
                    if (a.name > b.name) {
                        return 1;
                    } else if (a.name < b.name) {
                        return -1
                    }
                    return 0;
                });
                ratings.fields.map((rating) => {
                    rating.values.map((value) => {
                        value.value = value.id;
                        value.label = value.text;
                        return value;
                    })
                    return rating
                })
                state.ratings = { ...ratings };
            })
    }
})

// Action creators are generated for each case reducer function
export const { setUser, setCurrentCorrectorNum } = appSlice.actions;
export const selectUser = state => state.app.user;
export const selectCurrentCorrectorNum = state => state.app.correctorNum;
export const selectRatings = state => state.app.ratings;
// export const selectToken = state => state.app.user.accessToken;
export const selectToken = state => state.app.user.idToken;

export default appSlice.reducer