import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { addCorrection, uploadPhoto, deletePhoto, getExamById, getPDFById, updateExam } from "../../api/api";
import { saveAs } from 'file-saver';

export const putCorrection = createAsyncThunk(
    'putCorrection',
    async (params, { dispatch, getState }) => {
        const response = await addCorrection(params)
        return response
    }
)

export const uploadImage = createAsyncThunk(
    'uploadImage',
    async (params, { dispatch, getState }) => {
        const response = await uploadPhoto(params)
        return response
    }
)

export const deleteImage = createAsyncThunk(
    'deleteImage',
    async (params, { dispatch, getState }) => {
        const response = await deletePhoto(params)
        return response
    }
)

export const getExam = createAsyncThunk(
    'getExam',
    async (params, thunkAPI) => {
        const response = await getExamById(params)
        return response
    }
)

export const updateExamWithCorrections = createAsyncThunk(
    'updateExam',
    async (params, { dispatch, getState }) => {
        const response = await updateExam(params)
        return response
    }
)


export const downloadExamPDF = createAsyncThunk(
    'downloadExamPDF',
    async (params, thunkAPIc) => {
        const response = await getPDFById(params)
        return response
    }
)

export const examRatingSlice = createSlice({
    name: 'examRating',
    initialState: {
        exam: null,
        error: null
    },
    reducers: {
        setExam: (state, action) => {
            state.exam = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        clearAll: (state) => {
            state.exam = null;
            state.ratings = null;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getExam.pending, (state, action) => {
            })
            .addCase(getExam.fulfilled, (state, action) => {
                let exam = { ...action.payload };
                let corrections = [...exam.corrections];
                corrections.sort((a, b) => a.orderNumber - b.orderNumber)
                state.exam = { ...exam, corrections: corrections };
            })
            .addCase(putCorrection.pending, (state, action) => {
            })
            .addCase(putCorrection.fulfilled, (state, action) => {
            })
            .addCase(updateExamWithCorrections.pending, (state, action) => {
            })
            .addCase(updateExamWithCorrections.fulfilled, (state, action) => {
            })
            .addCase(downloadExamPDF.pending, (state, action) => {
            })
            .addCase(downloadExamPDF.fulfilled, (state, action) => {
                var filename = state.exam.student.number + ".pdf";
                // var blob = new Blob(action.payload, {type: "application/pdf;charset=utf-8"});
                var filesaver = saveAs(action.payload, filename);
            })
            .addCase(uploadImage.pending, (state, action) => { })
            .addCase(uploadImage.fulfilled, (state, action) => {
            })
            .addCase(deleteImage.pending, (state, action) => { })
            .addCase(deleteImage.fulfilled, (state, action) => {
            })
    },
})

// Action creators are generated for each case reducer function
export const { setExam, clearAll } = examRatingSlice.actions;
export const selectExam = state => state.examRating.exam;
export const selectError = state => state.examRating.error;

export default examRatingSlice.reducer