import {createSlice} from '@reduxjs/toolkit'

export const filtersSlice = createSlice({
    name: 'filters',
    initialState: {
        studentNum: '',
        correctorNum: '',
        secondCorrectorNum: '',
    },
    reducers: {
        setStudentNum: (state, action) => {
            state.studentNum = action.payload;
        },
        setCorrectorNum: (state, action) => {
            state.correctorNum = action.payload;
        },
        setSecondCorrectorNum: (state, action) => {
            state.secondCorrectorNum = action.payload;
        },
    }
})

// Action creators are generated for each case reducer function
export const {setStudentNum, setCorrectorNum, setSecondCorrectorNum} = filtersSlice.actions;
export const selectStudentNum = state => state.filters.studentNum;
export const selectCorrectorNum = state => state.filters.correctorNum;
export const selectSecondCorrectorNum = state => state.filters.secondCorrectorNum;

export default filtersSlice.reducer