import React from "react";
import {useMsal} from "@azure/msal-react";

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
    const {instance} = useMsal();

    const handleLogout = () => {
        instance.logoutPopup({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/"
        });
    }
    return (
        <a className="sign-out btn orange-btn" onClick={() => handleLogout(instance)}>Abmelden</a>
    )
}