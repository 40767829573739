import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { addExam, deleteAllExams, examsBatch, getAnalytics, getPDF, requestExams } from '../../api/api'
import { saveAs } from 'file-saver';

export const getExams = createAsyncThunk(
    'getExams',
    async (params, thunkAPI) => {
        const response = await requestExams(params)
        params ?
            console.log("examsSlice.getExams called with: " + JSON.stringify(params)) : console.log("examsSlice.getExams called without correctorNum");
        return response
    }
)

export const batchAddExams = createAsyncThunk(
    'batchAddExams',
    async (params, thunkAPI) => {
        const response = await examsBatch(params)
        return response
    }
)

export const getAllExamsPDF = createAsyncThunk(
    'getAllExamsPDF',
    async (params, thunkAPI) => {
        const response = await getPDF(params)
        return response
    }
)
export const getAnalyticsCSV = createAsyncThunk(
    'getAnalyticsCSV',
    async (params, thunkAPI) => {
        const response = await getAnalytics(params)
        return response
    }
)

export const deleteAllData = createAsyncThunk(
    'deleteAllData',
    async (params, thunkAPI) => {
        const response = await deleteAllExams();
        return response
    }
)

///not used
export const addExamTest = createAsyncThunk(
    'addExam',
    async (exam, thunkAPI) => {
        const response = await addExam(exam)
        return response
    }
)


export const examsSlice = createSlice({
    name: 'exams',
    initialState: {
        exams: [],
        loader: false
    },
    reducers: {
        setExams: (state, action) => {
            state.exams = action.payload;
        },
        setLoader: (state, action) => {
            state.loader = action.payload;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getExams.pending, (state, action) => {
                // Add user to the state array
                state.loader = true;
            })
            .addCase(getExams.fulfilled, (state, action) => {
                state.loader = false;
                let exams = JSON.parse(JSON.stringify(action.payload));
                exams.map((exam) => {
                    let corrections = [...exam.corrections];
                    corrections.sort((a, b) => a.orderNumber - b.orderNumber)
                    return { ...exam, corrections: corrections };
                })
                exams.sort((a, b) => a.student.number - b.student.number)
                state.exams = exams;
            })
            .addCase(addExamTest.pending, (state, action) => {
                // Add user to the state array
            })
            .addCase(addExamTest.fulfilled, (state, action) => {
                // Add user to the state array
                let exams = action.payload;
            })
            .addCase(batchAddExams.pending, (state, action) => {
                // Add user to the state array
            })
            .addCase(batchAddExams.fulfilled, (state, action) => {
                // Add user to the state array
                let exams = action.payload;
            })
            .addCase(getAllExamsPDF.pending, (state, action) => {
                // Add user to the state array
            })
            .addCase(getAllExamsPDF.fulfilled, (state, action) => {
                // Add user to the state array
                var filename = "Exams.zip";
                var filesaver = saveAs(action.payload, filename);
            })
            .addCase(getAnalyticsCSV.pending, (state, action) => {
            })
            .addCase(getAnalyticsCSV.fulfilled, (state, action) => {
                var filename = "auswertung.csv";
                var blob = new Blob(["\ufeff", action.payload], { type: 'text/csv;encoding:utf-8' });
                var filesaver = saveAs(blob, filename);
            })
    },
})

// Action creators are generated for each case reducer function
export const { setExams } = examsSlice.actions;
export const selectExams = state => {
    let exams = state.exams.exams.filter((exam) => {
        return (exam.student ? exam.student.number.includes(state.filters.studentNum) : true)
            && (exam.secondCorrector ? exam.secondCorrector.includes(state.filters.secondCorrectorNum) : true);
    });
    return exams.filter((exam) => {
        return (exam.firstCorrector ? exam.firstCorrector.includes(state.filters.correctorNum) : true);
    });
}

export const selectLoader = state => state.exams.loader;

export default examsSlice.reducer