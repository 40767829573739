import {configureStore} from '@reduxjs/toolkit'
import appReducer from './slices/appSlice'
import examsReducer from './slices/examsSlice'
import filtersReducer from './slices/filtersSlice'
import examRatingReducer from './slices/examRatingSlice'

export default configureStore({
    reducer: {
        app: appReducer,
        exams: examsReducer,
        filters: filtersReducer,
        examRating: examRatingReducer,
    },
    middleware: (getDefaultMiddleware) =>
        [
            ...getDefaultMiddleware({
                serializableCheck: false
            })
        ],
})