import React from 'react'
import "../styles/Auth.scss"
import {SignInButton} from "../components/SignInButton";


export function Auth() {
    return (
        <div className="auth-page">
            <div className="login-container">
                <div className="logo-container">
                    <div className="logo"/>
                </div>
                <div className="sign-in-container">
                    <SignInButton/>
                </div>
            </div>
        </div>
    )
}