import getAccessHeader from "./authWrapper";

const rootURL = process.env.REACT_APP_API_ROOT_URL;


async function requestExams(params) {
    console.info('api.requestExams called');
    const headers = await getAccessHeader();
    const options = {
        method: "GET",
        headers: headers
    };
    if (!params || !params.correctorNum || params.correctorNum === '*') {
        return fetch(rootURL + 'api/v1/exam/', options)
            .then(res => res.json())
            .then(res => res)
            .catch(err => console.error('request failed', err));
    } else {
        return fetch(rootURL + 'api/v1/exam/findByCorrector/' + params.correctorNum, options)
            .then(res => res.json())
            .then(res => res)
            .catch(err => console.error('request failed', err));
    }
}

async function requestStudents() {
    console.info('api.requestStudents called');
    const headers = await getAccessHeader();
    const options = {
        method: "GET",
        headers: headers
    }
    return fetch(rootURL + 'api/v1/student/', options)
        .then(res => res.json())
        .then(res => res)
        .catch(err => console.error('request failed', err));
}

function addStudent(student) {
    console.info('api.addStudents called');
    const options = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: student
    }
    return fetch(rootURL + 'api/v1/student/', options)
        .then(res => res.json())
        .then(res => res)
        .catch(err => console.error('request failed', err));
}

async function addCorrection(params) {
    const headers = await getAccessHeader();
    headers.append("Content-Type", "application/json");
    const options = {
        method: "put",
        headers: headers,
        body: JSON.stringify(
            {
                "id": params.correction.id,
                "correctionCompleted": true,
                "orderNumber": params.correction.orderNumber,
                "plainText": params.correction.plainText,
                "rating": params.correction.rating
            }),
    };
    console.info('api.addCorrection called with options:' + JSON.stringify(options));
    return fetch(rootURL + 'api/v1/correction/' + params.correction.id, options)
        .then(res => res.json())
        .then(res => res)
        .catch(err => console.error('request failed', err));
}

async function examsBatch(params) {
    console.info('api.examsBatch called');
    const headers = await getAccessHeader();
    headers.append("Content-Type", "application/json");
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(params.exams),
    };
    return fetch(rootURL + 'api/v1/exam/batch/', options)
        .then(res => res.json())
        .then(res => res)
        .catch(err => console.error('request failed', err));
}

async function getExamById(params) {
    console.info('api.getExamById called with id' + params.id);
    const headers = await getAccessHeader();
    const options = {
        method: "GET",
        headers: headers
    }
    return fetch(rootURL + 'api/v1/exam/' + params.id, options)
        .then(res => res.json())
        .then(res => res)
        .catch(err => console.error('request failed', err));
}

async function updateExam(params) {
    console.info('api.updateExam called with exam' + params.exam);
    let exam = examWithoutImages(params.exam)
    const headers = await getAccessHeader();
    headers.append("Content-Type", "application/json");
    const options = {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(exam)
    }

    return fetch(rootURL + 'api/v1/exam/' + params.exam.id, options)
        .then(res => res.json())
        .then(res => res)
        .catch(err => console.error('request failed', err));
}

function examWithoutImages(exam) {
    return { ...exam, corrections: [...exam.corrections.map((correction) => { return { ...correction, image: "" }; })] };
}

function addExam(exam) {
    console.info('api.addExam called with exam' + exam);
    const options = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(examWithoutImages(exam))
    }
    return fetch(rootURL + 'api/v1/exam/', options)
        .then(res => res.json())
        .then(res => res)
        .catch(err => console.error('request failed', err));
}

async function ratingOptionsGet() {
    console.info('api.ratingOptionsGet called');
    const headers = await getAccessHeader();
    headers.append("Content-Type", "application/json");
    const options = {
        method: "get",
        headers: headers,
    }
    return fetch('exam_schema.json', options)
        .then(response => response.json())
        .catch(error => console.error('request failed', error));

}

async function getPDFById(params) {
    console.info('api.getPDFById called with id: ' + params.id);
    const headers = await getAccessHeader();
    const options = {
        method: "get",
        headers: headers,
        responseType: 'blob',
    }
    return fetch(rootURL + 'api/v1/exam/pdf/' + params.id, options)
        .then(res => res.blob())
        .catch(err => console.error('request failed', err))
}

async function getPDF() {
    console.info('api.getPDF called');
    const headers = await getAccessHeader();
    const options = {
        method: "get",
        headers: headers,
        responseType: 'blob',
    }
    return fetch(rootURL + 'api/v1/exam/pdf/', options)
        .then(res => res.blob())
        .catch(err => console.error('request failed', err))
}

async function getAnalytics() {
    console.info('api.getAnalytics called');
    const headers = await getAccessHeader();
    const options = {
        method: "get",
        headers: headers,
        responseType: 'blob',
    }
    return fetch(rootURL + 'api/v1/exam/analytics/', options)
        .then(res => res.blob())
        .catch(err => console.error('request failed', err))
}

async function deleteAllExams() {
    console.info('api.deleteAllExams called');
    const headers = await getAccessHeader();
    const options = {
        method: "post",
        headers: headers,
    }
    return fetch(rootURL + 'api/v1/exam/deleteAllData/', options)
        .then(res => res.json())
        .then(res => res)
        .catch(err => console.error('request failed', err))
}

async function uploadPhoto(params) {
    console.info('api.uploadPhoto called with correction' + params.correction);
    const headers = await getAccessHeader();
    const formData = new FormData();
    formData.append("image", params.image);

    const options = {
        method: "POST",
        headers: headers,
        body: formData
    }

    return fetch(rootURL + 'api/v1/correction/' + params.correction.id + "/image", options)
        .then(res => res.json())
        .then(res => res)
        .catch(err => console.error('request failed', err));
}

async function deletePhoto(params) {
    console.info('api.uploadPhoto called with correction' + params.correction);
    const headers = await getAccessHeader();

    const options = {
        method: "DELETE",
        headers: headers,
    }

    return fetch(rootURL + 'api/v1/correction/' + params.correction.id + "/image", options)
        .then(res => res.json())
        .then(res => res)
        .catch(err => console.error('request failed', err));
}

export {
    requestExams,
    requestStudents,
    addStudent,
    addCorrection,
    getExamById,
    updateExam,
    addExam,
    ratingOptionsGet,
    examsBatch,
    getPDFById,
    getPDF,
    deleteAllExams,
    getAnalytics,
    uploadPhoto,
    deletePhoto
}