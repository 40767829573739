import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store'
import {Provider} from 'react-redux'
import {PublicClientApplication} from "@azure/msal-browser";
import {AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate} from "@azure/msal-react";
import {msalConfig} from "./authConfig";
import {BrowserRouter as Router} from "react-router-dom";
import {Auth} from "./pages/Auth";

export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <MsalProvider instance={msalInstance}>
                <Router>
                    <UnauthenticatedTemplate>
                        <Auth/>
                    </UnauthenticatedTemplate>
                    <AuthenticatedTemplate>
                        <App/>
                    </AuthenticatedTemplate>
                </Router>
            </MsalProvider>
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);

reportWebVitals();
