import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import "../styles/ExamList.scss"
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faMinusCircle, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons'
import { selectCurrentCorrectorNum, setCurrentCorrectorNum } from "../store/slices/appSlice";
import { getExams, selectExams, selectLoader, setExams } from "../store/slices/examsSlice";
import {
    selectCorrectorNum,
    selectSecondCorrectorNum,
    selectStudentNum,
    setCorrectorNum,
    setSecondCorrectorNum,
    setStudentNum
} from "../store/slices/filtersSlice";

export function ExamList() {
    const exams = useSelector(selectExams);
    const loader = useSelector(selectLoader);
    const studentFilter = useSelector(selectStudentNum);
    const currentCorrectorNum = useSelector(selectCurrentCorrectorNum);
    const correctorFilter = useSelector(selectCorrectorNum);
    const secondCorrectorFilter = useSelector(selectSecondCorrectorNum);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        console.log("ExamList opened");
        var delayInMilliseconds = 100; //1 second

        setTimeout(function () {
            dispatch(getExams({ correctorNum: currentCorrectorNum }));
        }, delayInMilliseconds);
    }, []);

    useEffect(() => {
        if (!currentCorrectorNum) {
            console.log("Corrector empty, Exam-Store cleaned up");
            dispatch(setExams([]));
        }
    }, [currentCorrectorNum]);

    const ratePupil = (exam) => {
        console.log("Change to student: " + exam.id);
        navigate({
            pathname: '/exam',
            search: '?examId=' + exam.id,
        })
    }

    const handleCurrentCorrectorChange = (e) => {
        const corrector = e.target.value.replace(/[^\d*]/g, '');
        console.log("CurrentCorrector changed to: " + corrector);
        if (corrector) {
            dispatch(setCurrentCorrectorNum(corrector));
        }
    }
    const handleCurrentCorrectorSelection = (e) => {
        if (!e || e.key === "Enter") {
            dispatch(getExams({ correctorNum: currentCorrectorNum }));
        }
    }
    const handleCorrectorChange = (e) => {
        console.log("FirstCorrector-Filter changed to: " + e.target.value);
        dispatch(setCorrectorNum(e.target.value.replace(/[^\d]/g, '')))
    }
    const handleSecondCorrectorChange = (e) => {
        console.log("SecondCorrector-Filter changed to: " + e.target.value);
        dispatch(setSecondCorrectorNum(e.target.value.replace(/[^\d]/g, '')))
    }
    const handleStudentChange = (event) => {
        console.log("Student-Filter changed to: " + event.target.value);
        dispatch(setStudentNum(event.target.value.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2")))
    }
    return (
        <div className="pupil-list-page loading">
            <div className="fields">
                <span className="number">Teiln.-Nr. {loader}</span>
                <span className="name">Name</span>
                <span className="corrector-1">EK</span>
                <span className="corrector-2">ZK</span>
                <span className="correction">Status</span>
                <div className="search-bars">
                    <div className="get-by-corrector">
                        <input type="text" name="name" placeholder="Korr-Nr." value={currentCorrectorNum}
                            onChange={handleCurrentCorrectorChange}
                            maxLength="4"
                            onKeyDown={(e) => handleCurrentCorrectorSelection(e)}
                        />
                        {currentCorrectorNum.length ? <FontAwesomeIcon className="delete-icon" icon={faTimes}
                            onClick={() => dispatch(setCurrentCorrectorNum(''))} /> : ''}
                        <FontAwesomeIcon className="search-icon" icon={faSearch}
                            onClick={() => handleCurrentCorrectorSelection()}
                        />
                    </div>
                    <div className="search-by-corrector">
                        <input type="text" name="name" placeholder="EK-Nr." value={correctorFilter}
                            onChange={handleCorrectorChange}
                            maxLength="4" />
                        {correctorFilter.length ? <FontAwesomeIcon className="delete-icon" icon={faTimes}
                            onClick={() => dispatch(setCorrectorNum(''))} /> : ''}
                        <FontAwesomeIcon className="search-icon" icon={faSearch}
                            onClick={() => dispatch(setCorrectorNum(correctorFilter))} />
                    </div>
                    <div className="search-by-corrector">
                        <input type="text" name="name" placeholder="ZK-Nr." value={secondCorrectorFilter}
                            onChange={handleSecondCorrectorChange}
                            maxLength="4" />
                        {correctorFilter.length ? <FontAwesomeIcon className="delete-icon" icon={faTimes}
                            onClick={() => dispatch(setSecondCorrectorNum(''))} /> : ''}
                        <FontAwesomeIcon className="search-icon" icon={faSearch}
                            onClick={() => dispatch(setSecondCorrectorNum(correctorFilter))} />
                    </div>
                    <div className="search-by-number">
                        <input type="text" name="name" placeholder="Teiln.-Nr." maxLength="7" value={studentFilter}
                            onChange={handleStudentChange} />
                        {studentFilter.length ? <FontAwesomeIcon className="delete-icon" icon={faTimes}
                            onClick={() => dispatch(setStudentNum(''))} /> : ''}
                        <FontAwesomeIcon className="search-icon" icon={faSearch}
                            onClick={() => dispatch(setStudentNum(studentFilter))} />
                    </div>
                </div>
            </div>
            {!loader && exams.length && currentCorrectorNum ? exams.map((exam) =>
                <div className="pupil-row" key={exam.id}>
                    <span className="number">{exam.student ? exam.student.number : '-'}</span>
                    <span className="name">{exam.student ? exam.student.name : '-'}</span>
                    <span className="corrector-1">{exam.firstCorrector}</span>
                    <span className="corrector-2">{exam.secondCorrector}</span>
                    <span className="correction">
                        {
                            exam.corrections && exam.corrections.length ?
                                [...exam.corrections].sort((a, b) => a.orderNumber - b.orderNumber).map((correction) =>
                                    <FontAwesomeIcon
                                        className={correction.correctionCompleted
                                            ? correction.rating === 1 ? 'fa-icon skipped' : 'fa-icon done'
                                            : 'fa-icon empty'}
                                        icon={correction.correctionCompleted
                                            ? correction.rating === 1 ? faMinusCircle : faCheckCircle
                                            : faCircle}
                                        key={correction.id} />
                                ) :
                                <React.Fragment>
                                    <FontAwesomeIcon className="fa-icon empty" icon={faCircle} />
                                    <FontAwesomeIcon className="fa-icon empty" icon={faCircle} />
                                    <FontAwesomeIcon className="fa-icon empty" icon={faCircle} />
                                    <FontAwesomeIcon className="fa-icon empty" icon={faCircle} />
                                </React.Fragment>

                        }
                    </span>
                    <div className="rate-btn">
                        <a className="rate btn outline-btn"
                            onClick={() => ratePupil(exam)}>Bearbeiten</a>
                    </div>
                </div>
            ) : (
                loader ? <div className="pupil-row loader">
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </div> :
                    (!currentCorrectorNum ?
                        <div className="pupil-row empty">Bitte Korr-Nr. eintragen</div> :
                        <div className="pupil-row empty">No exams found</div>
                    )

            )
            }
        </div>
    )
}