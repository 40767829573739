import React from "react";
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from "../authConfig";

export const SignInButton = () => {
    const { login } = useMsalAuthentication(InteractionType.Silent, loginRequest);

    const handleLogin = () => {
        login(InteractionType.Redirect, loginRequest)
            .then((res) => {
                return res;
            })
            .catch(e => {
                console.error(e);
            });
    }
    return (
        <a className="sign-in btn orange-btn" onClick={() => handleLogin()}>Sign in</a>
    );
}