import "./styles/App.scss"
import {ExamList} from "./pages/ExamList";
import {Route, Routes} from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import React, {useEffect} from "react";
import {Header} from "./components/Header";
import {ExamRating} from "./pages/ExamRating";
import {AddStudent} from "./pages/AddStudent";
import {useDispatch, useSelector} from "react-redux";
import {selectUser, setUser} from "./store/slices/appSlice";
import {getAccessResponse} from "./api/authWrapper";

function App() {
    const {accounts} = useMsal();
    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    useEffect(() => {
        if (accounts.length) {
            getAccessResponse()
                .then((response) => {
                    //console.log(response);
                    dispatch(setUser(response));
                })
                .catch((e) => {
                    console.error(e);
                })
        }
    }, [accounts]);

    return (
        <div className="app" id="app">
            <div className="container">
                <Header user={user}/>
                <div className="content">
                    <Routes>
                        <Route path="/" element={<ExamList/>}/>
                        <Route path="/exam/" element={<ExamRating/>}/>
                        <Route path="/add-student/" element={<AddStudent/>}/>
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default App;
