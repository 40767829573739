import React, {useState} from 'react'
import "../styles/AddStudent.scss"


export function AddStudent() {
    const [studentNumber, setStudentNumber] = useState('');
    const [studentName, setStudentName] = useState('');
    const [corrector1Num, setCorrector1Num] = useState('');
    const [corrector2Num, setCorrector2Num] = useState('');

    const handleNumberInput = (value, setter, limit) => {
        value = value.replace(/\D/, '');
        value = value > limit ? value.substring(0, limit) + "" : value;
        setter(value);
    }

    return (
        <div className="add-student-container">
            <h1 className="add-student-row ">Add Exam</h1>
            <div className="add-student-row">
                <label className="light-label"> Student Number </label>
                <input type="text" name="name"
                       value={studentNumber}
                       onChange={event => handleNumberInput(event.target.value, setStudentNumber, 8)}/>
            </div>
            <div className="add-student-row">
                <label className="light-label"> Student Name </label>
                <input type="text" name="name" value={studentName}
                       onChange={event => setStudentName(event.target.value)}/>
            </div>
            <div className="add-student-row">
                <label className="light-label"> Corrector 1 Number </label>
                <input type="text" name="name"
                       value={corrector1Num}
                       onChange={event => handleNumberInput(event.target.value, setCorrector1Num, 4)}/>
            </div>
            <div className="add-student-row">
                <label className="light-label"> Corrector 2 Number </label>
                <input type="text" name="name"
                       value={corrector2Num}
                       onChange={event => handleNumberInput(event.target.value, setCorrector2Num, 4)}/>
            </div>
            <div className="add-student-row button-row">
                <a className="btn grey-btn">Abbrechen</a>
                <a className="btn orange-btn">Teiln. hinzufügen</a>
            </div>

        </div>
    )
}


