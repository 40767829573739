import { loginRequest } from "../authConfig";
import { msalInstance } from "../index";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const getAccessHeader = async () => {
    const accounts = msalInstance.getAllAccounts();
    if (!accounts.length) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const request = {
        ...loginRequest,
        loginHint: accounts[0].username // For v1 endpoints, use upn from idToken claims
    };

    return msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
    })
        .then((response) => {
            return response
        })
        .catch(async (error) => {
            console.log("Error while trying to acquire Token silently: " + error);
            msalInstance.acquireTokenPopup(request)
                .then((response) => {
                    return response;
                });
        })
        .then((response) => {
            const headers = new Headers();
            const bearer = `Bearer ${response.idToken}`;
            headers.append("Authorization", bearer);
            return headers;
        });
}


export const getAccessResponse = async () => {
    const accounts = msalInstance.getAllAccounts();
    if (!accounts.length) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    const request = {
        ...loginRequest,
        account: accounts[0],
        loginHint: accounts[0].username // For v1 endpoints, use upn from idToken claims
    };

    return msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
    })
        .then((response) => {
            return response
        })
        .catch(async (error) => {
            console.log("Error while trying to acquire Token silently: " + error);
            msalInstance.acquireTokenPopup(request)
                .then((response) => {
                    return response;
                });
        })
        .catch(async (error) => {
            if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return await msalInstance.acquireTokenPopup(request);
            }
        });
}

export default getAccessHeader