import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { TestEditor } from "../components/TestEditor";
import "../styles/ExamRating.scss"
import {
    clearAll,
    downloadExamPDF,
    getExam,
    putCorrection,
    selectError,
    selectExam,
    setExam,
    updateExamWithCorrections,
    uploadImage
} from "../store/slices/examRatingSlice";


import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { getRatingOptions, selectRatings, selectToken } from "../store/slices/appSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";


export function ExamRating() {
    const dispatch = useDispatch();
    const search = useLocation().search;
    const examId = new URLSearchParams(search).get('examId');
    const exam = useSelector(selectExam);
    const ratings = useSelector(selectRatings);
    const error = useSelector(selectError);
    const token = useSelector(selectToken);
    const navigate = useNavigate();
    const [isSynchronizing, setIsSychronizing] = useState(false)
    const [downloadPending, setDownloadPending] = useState(false);

    useEffect(() => {
        Promise.all([
            dispatch(getRatingOptions()),
            dispatch(getExam({ id: examId }))
        ])
        console.log("ExamRating opened");
        return () => {
            //your cleanup code codes here
            dispatch(clearAll())
        };
    }, [token]);

    useEffect(() => {
        if (exam && exam.corrections && !exam.corrections.length) {
            let corrections = [];
            for (let i = 0; i < 4; i++) {
                corrections.push({
                    "id": 0,
                    "correctionCompleted": false,
                    "orderNumber": i,
                    "plainText": "",
                    "rating": "",
                    "generated_png": []
                })
            }
            let examWithCorrections = {
                ...exam,
                corrections: corrections
            };

            dispatch(updateExamWithCorrections({ exam: examWithCorrections }))
                .then((res) => {
                    console.log("Sucessfully updated to: " + res);
                })
                .catch(err => console.error("Error while updating exam: " + err))
        }
    }, [exam]);

    function handleBackClick() {
        console.log("Go back to ExamList");
        dispatch(clearAll())
        navigate({
            pathname: '/',
        })
    }

    function handleBackAndSave() {
        console.log("Clicked Back&Save");
        addCorrectionsAndComments(false);
        handleBackClick();
    }

    function handleRatingChange(value, index) {
        console.log("Rating changed: " + JSON.stringify(value) + " at Aufgabe Index: " + index);

        let oldCorrection = exam.corrections[index];
        let newCorrection = { ...oldCorrection, rating: value.id };

        let corrections = [...exam.corrections.filter((correction) => correction.id !== oldCorrection.id), newCorrection];

        dispatch(setExam({
            ...exam,
            corrections: corrections.toSorted((a, b) => a.orderNumber - b.orderNumber)
        }));
    }

    function getDefaultObj(rating, index) {
        return rating.values.find((value) => {
            let corrections = exam.corrections.toSorted((a, b) => a.orderNumber - b.orderNumber)
            return value.id === corrections[index].rating;
        })
    }

    function handlePDFDownload() {
        setDownloadPending(true);
        dispatch(downloadExamPDF({ id: exam.id }))
            .then((res) => {
                return res;
            })
            .catch(error => console.error(error))
            .finally(() => setDownloadPending(false));
    }

    function saveCorrectionsAndRefreshExam() {
        addCorrectionsAndComments(true)
    }
    function addCorrectionsAndComments(refreshExam) {
        if (refreshExam)
            setIsSychronizing(true)
        Promise.all([...exam.corrections
            .filter((correction) => correction.image && !(typeof correction.image === 'string' || correction.image instanceof String))
            .map(correction => dispatch(
                uploadImage({
                    examId: exam.examId,
                    correction: correction,
                    image: correction.image
                })
            ))])
        Promise.all(
            [exam.corrections.map((correction) => {
                return dispatch(putCorrection({
                    examId: exam.id,
                    correction: { ...correction, image: "" },
                    getExam: true
                }))
            }),
            ]
        )
        dispatch(updateExamWithCorrections({ exam: exam })).then(() => {
            if (refreshExam)
                dispatch(getExam({ id: examId })).then(() => setIsSychronizing(false))
        })
        console.log("Corrections added");
    }

    function setCorrection(correctionId, value, image) {
        console.log("Corrections set to " + correctionId + " " + value);

        let oldCorrection = exam.corrections.find((correction) => {
            return correction.id === correctionId;
        });

        let newCorrection = { ...oldCorrection, plainText: value, image: image };
        let corrections = [...exam.corrections.filter((correction) => correction.id !== correctionId), newCorrection];

        dispatch(setExam({
            ...exam,
            corrections: corrections.toSorted((a, b) => a.orderNumber - b.orderNumber)

        }));
    }

    function handleTextChange(e) {
        dispatch(setExam({ ...exam, comments: e.target.value }))
    }


    return (

        <div>
            {isSynchronizing && <div className='overlay' >
                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>}
            <div className="exam-container">
                {error &&
                    <div className="error-container">
                        No Exam found
                    </div>}
                <div className="btn-row">
                    <div className="go-back-btn">
                        <button className="btn grey-btn" onClick={handleBackClick}>Zurück</button>
                    </div>
                    <div className="go-back-and-save-btn">
                        <button className="btn grey-btn" onClick={handleBackAndSave}>Speichern & Zurück</button>
                    </div>
                    <div className="download-pdf-btn">
                        <button className="btn grey-btn" disabled={downloadPending} onClick={handlePDFDownload}>
                            {downloadPending && (
                                <FontAwesomeIcon className="spinner" icon={faCircleNotch} spin/>
                            )}
                            Download PDF
                        </button>
                    </div>
                </div>
                {!exam && !error && <div class="lds-ring"><div></div><div></div><div></div><div></div></div>}
                {exam && <div className="exam-info">

                    <div className="info">
                        <div className="info-row corrector-1">
                            <label className="light-label">EK-Nummer:</label>
                            <div>{exam.firstCorrector ? exam.firstCorrector : '-'}</div>
                        </div>
                        <div className="info-row id">
                            <label className="light-label">Teiln.-Nr.:</label>
                            <div>{exam.student?.number}</div>
                        </div>
                        <div className="info-row corrector-2">
                            <label className="light-label">ZK-Nummer:</label>
                            <div>{exam.secondCorrector ? exam.secondCorrector : '-'}</div>
                        </div>
                        <div className="info-row name">
                            <label className="light-label">Name Teiln.:</label>
                            <div>{exam.student ? exam.student.name : '-'}</div>
                        </div>
                    </div>

                </div>}
                {exam &&
                    <React.Fragment>
                        <div className="table-content">
                            {ratings.fields.map((rating, index) =>
                                <div className="test-rating" key={index}>
                                    <label className="light-label"> {rating.name} </label>
                                    <Select className="rating-dropdown"
                                        placeholder="Bitte auswählen"
                                        defaultValue={getDefaultObj(rating, index)}
                                        options={rating.values}
                                        onChange={(value) => handleRatingChange(value, index)} />
                                </div>
                            )}
                            <div className='test-rating' >
                                <label className='light-label'>Allgemein</label>
                                <div className="test-comment">
                                    <textarea value={exam.comments} onChange={handleTextChange} />
                                </div>
                            </div>
                            <div className="test-text">
                                <a className="rate btn outline-btn" onClick={saveCorrectionsAndRefreshExam}>Alles speichern</a>
                            </div>
                        </div>
                        <div className="test-editors-container">
                            {exam.corrections.map((correction) =>
                                <TestEditor correction={correction} examId={exam.id} key={correction.id}
                                    setCorrection={setCorrection} addCorrections={saveCorrectionsAndRefreshExam} />
                            )}
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}


